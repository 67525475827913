.menu {
  width: 250px;
}

.header {
  padding: 6px 16px 8px 16px;
  font-size: 16px;
  font-weight: 600;
}

.form {
  padding: 8px 16px 6px 16px;
}

.previous {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
          justify-content: flex-end;
  font-size: 12px;
}

.submit {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
          justify-content: flex-end;
}
