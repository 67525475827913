.wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: start;
          align-items: flex-start;
  height: 34px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-left: 30px;
  margin-top: 18px;
  margin-bottom: 10px;
}

.col {
  padding-left: 8px;
  padding-right: 16px;
}

.col1 {
  width: 250px;
}

.col2 {
  -webkit-box-flex: 1;
          flex: 1;
  overflow: hidden;
}

.col3 {
  width: 70px;
}

.col4 {
  width: 100px;
}

.col5 {
  width: 120px;
}

.col6 {
  width: 70px;
}

.col7 {
  width: 100px;
}

.col8 {
  width: 110px;
}

.col9 {
  width: 120px;
}

.col10 {
  width: 110px;
}

.col11 {
  width: 10px;
}

.col12 {
  -webkit-box-flex: 1;
          flex: 1;
}

.textOutlet {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding-right: 16px;
  width: 60px;
}
