.wrapper {
  z-index: 9999999;
  position: fixed;
  top: 100%;
  left: 0px;
  width: 100%;
  height: 0%;
}

.bar {
  width: 600px;
  height: 50px;
  margin: auto;
  bottom: 70px;
  background-color: #F7F9FA;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  box-shadow: 0 0 0 1px rgba(99,114,130,.16), 0 8px 16px rgba(27,39,51,.08);
}

.icon {
  color: #1990FF;
  margin-right: 10px;
  font-size: 18px;
}

.filename {
  -webkit-box-flex: 1;
          flex: 1;
}

.filesLeft {
  margin-left: 20px;
}

.progressOuter {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 5px;
  background-color: #DDDFE1;
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
}

.progressInner {
  height: 100%;
  background-color: #1990FF;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
}