.customChild {
  height: 100%;
}

.customChild :global(div.ant-select-selection) {
  height: 100% !important;
}

.customChild :global(li.ant-select-search) {
  position: absolute !important;
}