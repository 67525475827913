.divider1 {
  height: 1px;
  background-color: rgba(0,0,0,0.1);
}

.divider2 {
  height: 1px;
  background-color: rgba(0,0,0,0.2);
  margin-bottom: 8px;
}

.divider3 {
  height: 1px;
  background-color: rgba(0,0,0,0.2);
  margin-top: 12px;
}
