.form :global(.ant-form-item) { 
  margin-bottom: 5px !important;
}

.formItem {
  width: 500px;
  display: inline-block !important;
}

.addButton {
  margin-top: 4px;
  margin-left: 8px;
}