.wrapper {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}

.main {
  -webkit-box-flex: 1;
          flex: 1;
  padding-top: 24px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  overflow: hidden;
  position: relative;
}

.section1 {
  margin-right: 64px;
}

.title {
  margin-top: 0px;
  font-size: 28px;
  font-weight: 500;
  color: rgba(0,0,0,0.85);
}

.section2 {
  margin-right: 64px;
  margin-top: 24px;
}

.customer {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0,0,0,0.85);
  margin-bottom: 2px;
}

.customerContact {
  font-weight: 500;
  color: rgba(0,0,0,0.85);
}

.tags {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  margin-top: 12px;
}

.tag {
  height: 22px;
  line-height: 22px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
  color: #1990FF;
  background-color: #E6F7FF;
  border: 1px solid #91D5FF;
  margin-right: 8px;
  font-size: 11px;
  cursor: default;
}

.section3 {
  margin-right: 48px;
  margin-top: 24px;
}

.detail {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  margin-bottom: 8px;
}

.detailLabel {
  width: 95px;
  color: #999;
}

.detailContent {
  color: rgba(0,0,0,0.85);
  font-weight: 500;
}

.buttons {
  position: absolute;
  top: 24px;
  right: 0px;
  display: -webkit-box;
  display: flex;
}

.buttonBadge sup {
  background-color: #E6F7FF;
  color: #0091FF;
  box-shadow: 0 0 0 1px #91D5FF inset;
  top: 5px;
  font-size: 10px;
}

.tabs {
  height: 48px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.tab {
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  margin-right: 32px;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  border-bottom: 2px solid white;
}

.tabActive {
  border-bottom: 2px solid #0091FF;
}