.button {
  cursor: pointer;
  color: #999 !important;
  font-size: 14px;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.button.primary,
.button:hover {
  color: #1990FF !important;
}

.button:active {
  color: #0B6DD9 !important;
}

.button.danger:hover {
  color: #F5222D !important;
}

.button.danger:active {
  color: #CE1221 !important;
}

.button.disabled {
  cursor: default !important;
  color: #CCC !important;
}