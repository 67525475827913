.table tbody tr {
  cursor: pointer;
}

.status {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
}

.indicator {
    margin-right: 8px;
    height: 6px;
    width: 6px;
    border-radius: 3px;
}

.indicatorGrey {
    background-color: grey;
}

.indicatorBlue {
    background-color: blue;
}

.indicatorGreen {
    background-color: #6DD400;
}