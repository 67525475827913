.form {
  max-width: 900px;
  margin: auto !important;
}

.form :global(.ant-form-item) { 
  margin-bottom: 5px !important;
}

.matches {
  max-height: 300px;
  overflow: auto;
}
