.wrapper {
  max-height: 395px;
  overflow: scroll;
}

.table th, td {
  padding: 4px 16px;
}

.table th:first-child, td:first-child {
  padding-left: 0px;
}

.table th:last-child, td:last-child {
  padding-right: 0px;
}

.num {
  text-align: right;
}
