.popover :global(.ant-popover-inner-content) {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  width: 500px;
  height: 400px;
  padding: 0px !important;
}

.wrapper {
  width: 100%;
  height: 100%;
  outline: none;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  display: flex;
  overflow: hidden;
}

.header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  height: 54px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 1px solid #EEE;
}

.title {
  font-weight: 600;
  font-size: 17px;
}

.empty {
  -webkit-box-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.dropzone {
  z-index: 9999999;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.8);
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.icon {
  font-size: 80px;
  margin-bottom: 10px;
}

.text {
  font-size: 20px;
}
