.appBar {
  width: 100%;
  height: 60px;
  background-color: #173856;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  position: relative;
}

.appBar a {
  text-decoration: none;
  color: inherit;
}

.appBar a:hover {
  color: inherit;
}