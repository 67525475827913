.wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
  height: 50px;
  padding-left: 30px;
}

.col {
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  color: rgba(0,0,0,0.85);
  font-size: 14px;
  font-weight: 500;
  padding-left: 8px;
  padding-right: 16px;
}

.col1 {
  width: 300px;
}

.col2 {
  width: 100px;
}

.col3 {
  width: 120px;
}

.col4 {
  width: 120px;
}

.col5 {
  -webkit-box-flex: 1;
          flex: 1;
}

