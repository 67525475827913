.footer {
  min-height: 65px;
  padding-left: 20px;
  padding-right: 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
}

.footer.border {
  border-top: 1px solid #e8e8e8;
}

.title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-weight: 500;
  margin-right: 12px;
}

.left {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
}

.right {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
  margin-left: auto;
}