.wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
  height: 50px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.closer {
  width: 30px;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  margin-top: 2px;
  color: #999;
  cursor: pointer;
}

.closer:hover {
  color: #1990FF;
}

.col {
  height: 100%;
}

.col1 {
  width: 180px;
}

.col2 {
  width: 300px;
}

.col3 {
  width: 300px;
}

.col4 {
  width: 300px;
}

.textOutlet {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 8px;
  padding-right: 14px;
  color: #595959;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.moreButton {
  width: 30px;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  cursor: pointer;
  outline: none;
}

.moreButton:hover {
  background-color: #FAFAFA;
}

.moreButton:focus {
  background-color: rgba(230,247,255,0.5);
}