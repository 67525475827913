.wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: baseline;
          align-items: baseline;
  padding-left: 38px;
  padding-bottom: 20px;
}

.detail {
  height: 30px;
  display: -webkit-box;
  display: flex;
}

.label {
  width: 200px;
  color: #999;
}

.value {
  color: rgba(0,0,0,0.85);
  font-weight: 500;
}

.edit {
  display: none;
  margin-left: 5px;
  cursor: pointer;
}

.detail:hover .edit {
  display: block;
}
