.header {
  display: -webkit-box;
  display: flex;
  margin-bottom: 16px;
}

.left {
  -webkit-box-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: flex;
}

.details {
  display: -webkit-box;
  display: flex;
  padding: 0px 16px;
}

.detail {
  height: 32px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  margin-left: 16px;
}

.detail:first-of-type {
  margin-left: 0px;
}

.label {
  color: #9e9e9e;
}

.value {
  font-weight: 500;
  margin-left: 4px;
}
