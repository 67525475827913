.table tbody tr {
  cursor: pointer;
}

.status {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
}

.indicator {
    margin-right: 8px;
    height: 6px;
    width: 6px;
    border-radius: 3px;
}

.indicatorBlue {
    background-color: #0091FF;
}

.indicatorOrange {
    background-color: orange;
}

.indicatorPurple {
    background-color: #7A50FB;
}

.indicatorGreen {
    background-color: #6DD400;
}