.wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
  height: 42px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-left: 38px;
}

.actions {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.totals {
  display: -webkit-box;
  display: flex;
}
