html, body {
  height: 100%;
  margin: 0px;
  background-color: #F0F4F5 !important;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

:global(.ant-form-item) { margin-bottom: 10px !important; }
