.wrapper {
  min-height: 50px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding: 5px 0px;
}

.col {
  height: 100%;
}

.partNos {
  width: 320px;
}

.manufacturer {
  width: 250px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.partMatch {
  width: 80px;
  text-align: center;
}

.quantity {
  width: 100px;
  padding-right: 16px;
}

.service {
  width: 160px;
}

.suppliers {
  -webkit-box-flex: 1;
          flex: 1;
}

.textInput {
  width: 100%;
  height: 32px;
  border: none;
  background: none;
  outline: none;
  border-radius: none;
  padding-left: 8px;
  padding-right: 16px;
  color: #595959;
  cursor: text;
  text-overflow: ellipsis;
}

.textInput::-webkit-input-placeholder {
  color: #bfbfbf;
}

.textInput::-moz-placeholder {
  color: #bfbfbf;
}

.textInput:-ms-input-placeholder {
  color: #bfbfbf;
}

.textInput::-ms-input-placeholder {
  color: #bfbfbf;
}

.textInput::placeholder {
  color: #bfbfbf;
}

.textInput:hover {
  background-color: #FAFAFA;
}

.textInput:focus {
  background-color: rgba(230, 247, 255, 0.5);
  cursor: text;
}

.antInput {
  width: 100%;
}

.antInput :global(.ant-input),
.antInput :global(.ant-select-selection--single),
.antInput :global(.ant-select-selection--multiple) {
  border: none;
  box-shadow: none !important;
}

.antInput :global(.ant-input):hover,
.antInput :global(.ant-select-selection--single):hover,
.antInput :global(.ant-select-selection--multiple):hover {
  background-color: #FAFAFA;
}

.antInput :global(.ant-select-selection--multiple),
.antInput :global(.ant-select-selection__choice) {
  cursor: pointer !important;
}

.manufacturerAutoComplete {
  width: 226px !important;
}

.createManufacturerButton {
  position: relative;
  right: 30px;
  z-index: 1;
}

.actionsButtonWrapper {
  width: 30px;
  height: 32px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  cursor: pointer;
}

.actionsButtonWrapper:hover {
  background-color: #FAFAFA;
}
