.wrapper {
  height: 50px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.col {
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.partNos {
  width: 320px;
}

.manufacturer {
  width: 250px;
}

.partMatch {
  width: 80px;
}

.quantity {
  width: 100px;
  -webkit-box-pack: end;
          justify-content: flex-end;
  padding-right: 32px;
}

.service {
  width: 160px;
}

.suppliers {
  -webkit-box-flex: 1;
          flex: 1;
}
