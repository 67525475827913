.logo {
  width: 112px;
  height: 29px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 2px;
}

.user {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.avatar {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background-color: #1690FF;
  color: #FFF;
  text-align: center;
}

.details {
  margin-left: 10px;
}

.name {
  font-weight: bold;
}