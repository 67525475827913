.header {
  display: -webkit-box;
  display: flex;
  margin-bottom: 16px;
}

.left {
  -webkit-box-flex: 1;
          flex: 1;
}

.right {
  
}