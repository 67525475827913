.wrapper {
  -webkit-box-flex: 1;
          flex: 1;
  overflow-y: scroll;
}

.item {
  padding: 15px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #EEE;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.col {
  color: #333;
}

.thumbCol {
  width: 70px;
  height: 45px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.thumbImage {
  max-width: 100%;
  max-height: 100%;
}

.detailsCol {
  -webkit-box-flex: 1;
          flex: 1;
}

.meta {
  color: #999;
  font-size: 12px;
  margin-top: 2px;
}

.buttonCol {
  margin-left: 10px;
  margin-right: 10px;
}

.buttons {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.item:hover .buttons {
  opacity: 1;
}

.button {
  cursor: pointer;
  margin-right: 16px;
  color: #666 !important;
  font-size: 18px;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.button.primary,
.button:hover {
  color: #1990FF !important;
}

.button:active {
  color: #0B6DD9 !important;
}

.button.danger:hover {
  color: #F5222D !important;
}

.button.danger:active {
  color: #CE1221 !important;
}
