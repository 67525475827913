.fitToScreen {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}

.headerWrapper {
  height: 80px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #FFF;
  border-bottom: 1px solid #DDD;
}

.headerInner {
  height: 100%;
  margin: auto;
  position: relative;
}

.headerLeft {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.headerRight {
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.title {
  font-size: 24px;
  font-weight: 500;
  color: #333;
}

.backButton {
  font-size: 24px;
  color: #333;
  margin-right: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
}

.backButton:hover {
  color: #1990FF;
}



.contentWrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  display: block;
}

.contentWrapper.fixedFooterReserve {
  padding-bottom: 95px;
}

.contentInner {
  margin: auto;
}

.fitToScreen .contentWrapper {
  -webkit-box-flex: 1;
          flex: 1;
  height: 1px;
  padding: 0px;
}

.fitToScreen .contentInner {
  width: 100%;
  height: 100%;
}



.footerWrapper {
  z-index: 1;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 65px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #FFF;
  border-top: 1px solid #DDD;
}

.footerInner {
  height: 100%;
  margin: auto;
  position: relative;
}

.footerLeft {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.footerRight {
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}