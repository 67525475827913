.section {
  position: relative;
}

.section.borderTop {
  border-top: 1px solid #e8e8e8;
}

.section.borderBottom {
  border-bottom: 1px solid #e8e8e8;
}

.header {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
}

.headerLeft {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
}

.headerRight {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
  margin-left: auto;
}

.title {
  color: rgba(0, 0, 0, 0.75);
  font-size: 18px;
  font-weight: 500;
  margin-right: 12px;
}

.content {
  padding: 30px;
}