.wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
          justify-content: flex-end;
}

.title {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.popover {
  width: 220px;
}

.popover :global(.ant-popover-inner-content) {
  padding: 0px;
}

.list :global(.ant-list-item) {
  padding: 12px 16px;
}

.listItem {
  cursor: pointer;
}

.listItem:hover {
  background-color: rgba(230, 247, 255, 0.5);
}
