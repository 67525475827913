.card {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
  background-color: #FFF;
  border: 1px solid #e8e8e8;
}

.dropzone {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 10px;
  background-color: white;
  opacity: 1;
  width: 100%;
  height: 100%;
  min-height: 200px;
}

.dropzoneBorder {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  border: 4px dashed #1990FF;
}

.dropzoneIcon {
  font-size: 90px;
  line-height: 90px;
  color: #CCC !important;
}

.dropzoneTitle {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
}

.dropzoneText {
  color: #999;
  margin-top: 5px;
}