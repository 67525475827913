.wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
  height: 50px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.closer {
  width: 30px;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  margin-top: 2px;
  color: #999;
  cursor: pointer;
}

.closer:hover {
  color: #1990FF;
}

.col {
  height: 100%;
}

.col1 {
  width: 300px;
}

.col2 {
  width: 180px;
}

.col3 {
  width: 300px;
}

.col4 {
  width: 100px;
}

.col5 {
  width: 120px;
}

.textInput {
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  outline: none;
  border-radius: none;
  color: #595959;
  padding-left: 8px;
  padding-right: 16px;
  cursor: pointer;
}

.textInput.rightAlign {
  text-align: right;
  padding-right: 32px;
}

.textInput::-webkit-input-placeholder {
  color: #CCC;
  font-weight: 300 !important;
}

.textInput::-moz-placeholder {
  color: #CCC;
  font-weight: 300 !important;
}

.textInput:-ms-input-placeholder {
  color: #CCC;
  font-weight: 300 !important;
}

.textInput::-ms-input-placeholder {
  color: #CCC;
  font-weight: 300 !important;
}

.textInput::placeholder {
  color: #CCC;
  font-weight: 300 !important;
}

.textInput.invalid::-webkit-input-placeholder {
  color: rgba(255,4,4,0.8);
}

.textInput.invalid::-moz-placeholder {
  color: rgba(255,4,4,0.8);
}

.textInput.invalid:-ms-input-placeholder {
  color: rgba(255,4,4,0.8);
}

.textInput.invalid::-ms-input-placeholder {
  color: rgba(255,4,4,0.8);
}

.textInput.invalid::placeholder {
  color: rgba(255,4,4,0.8);
}

.textInput:hover {
  background-color: #FAFAFA;
}

.textInput:focus {
  background-color: rgba(230,247,255,0.5);
  cursor: text;
}

.textInput.notes {
  font-weight: 400;
  font-size: 12px;
  font-style: italic;
  color: #999;
}

.textOutlet {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 8px;
  padding-right: 16px;
  color: #595959;
  cursor: default;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textOutlet:hover {
  background-color: #FAFAFA;
}

.manufacturerNo {
  color: #1990FF;
  cursor: pointer;
}

.moreButton {
  width: 30px;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  cursor: pointer;
  outline: none;
}

.moreButton:hover {
  background-color: #FAFAFA;
}

.moreButton:focus {
  background-color: rgba(230,247,255,0.5);
}